import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";







function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
             
              isBlog={false}
              title="IHNA Website"
              description="Contributed to the development of the IHNA website, handling both front-end and back-end tasks. Created reusable React.js components to enhance modularity and consistency throughout the website. Styled the website using TailwindCSS and Astro.build to ensure a modern and responsive design. Integrated backend APIs created with FastAPI into the website, ensuring smooth data flow and communication between the client-side and server-side components. Developed and maintained the blogs page using Astro and TailwindCSS, providing an engaging and user-friendly interface for content management."
             
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              
              isBlog={false}
              title="HCI Blog Page Development"
              description="Designed and developed the HCI blog page using Astro.build and TailwindCSS. The focus of the project was to create an engaging, user-friendly interface to display blog content. Ensured that the website was responsive and visually appealing by leveraging modern design principles. Key Contributions: Created reusable components and styled the blog interface using TailwindCSS. Ensured seamless content management and presentation, enhancing the user experience."
       
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
             
              isBlog={false}
              title="NephroPlus IoT Project"
              description="Worked on integrating IoT functionalities for NephroPlus using Raspberry Pi devices. Utilized Node-RED for workflow automation, enabling efficient data processing and integration of IoT devices. Managed and optimized data storage using SQLite, ensuring performance and reliability for IoT-related data. Developed React.js reusable components to maintain a consistent and scalable front-end architecture. Key Contributions: Enabled seamless device connectivity and control, ensuring reliable integration between hardware (Raspberry Pi) and software components. Played a significant role in enhancing the user experience through optimized workflows."
                      
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              
              isBlog={false}
              title="Vodafone MVP"
              description="Developed server-side rendering for the Vodafone MVP project using Next.js, which significantly improved application performance and SEO. Integrated Contentful, a headless CMS, to allow for dynamic content rendering across various landing pages. This enabled seamless updates to website content without requiring direct code changes. Key Contributions: Managed the integration of Next.js and Contentful, ensuring dynamic and responsive landing pages that improved user engagement and search engine visibility."
             
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              
              isBlog={false}
              title="Flex Remit"
              description="Designed and developed RESTful APIs for the Flex Remit project using Nest.js and Express.js. The APIs were integral to enabling seamless remittance transactions for the application. Integrated the project with Singaporean banks to support financial transactions and developed wallet functionalities for users to manage funds within the platform. Managed the overall database schema design to ensure smooth functionality, scalability, and performance. Key Contributions: Ensured secure API endpoints by implementing robust authentication and authorization measures. Provided detailed API documentation for stakeholders and worked closely with junior developers, offering mentorship and guidance on project-specific technologies."
            
            />
          </Col>

         

          <Col md={4} className="project-card">
            <ProjectCard
             
              isBlog={false}
              title="VerifyX"
              description="Developed and integrated SDK verification processes for Flex Remit, focusing on ensuring secure and smooth operations. This project was essential in verifying third-party SDKs integrated into the application, thereby enhancing the security and reliability of the platform. Key Contributions: Worked collaboratively with cross-functional teams, including product managers and designers, to ensure secure and successful integration. Provided mentorship to junior developers on project implementation and best practices."
            
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
             
              isBlog={false}
              title="NexMart"
              description="Developed and maintained comprehensive API documentation for web applications, including endpoint descriptions, request/response structures, and usage examples. Collaborated with the development team to ensure accurate and detailed API documentation. Additionally, contributed to building web applications using the Django framework, working on both front-end and back-end services. Key Contributions: Developed back-end services that were efficient, scalable, and secure. Assisted in managing MySQL databases, performing complex queries, and ensuring database integrity."
        
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
