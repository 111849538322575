import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            At the core of our work is a passion for developing innovative,
            scalable, and responsive web applications that meet the highest
            industry standards. We are led by Mohamed Arshad, a self-taught
            full-stack software developer with extensive experience in both
            front-end and back-end development. His expertise spans across
            modern web technologies and frameworks, including JavaScript, React,
            Node.js, NestJS, Express.js, MySQL, and more. With a strong
            foundation in full-stack development and a hands-on approach,
            Mohamed has contributed to various projects, building and
            maintaining applications that cater to real-world needs. His work
            demonstrates not only technical proficiency but also a deep
            understanding of agile methodologies, problem-solving, and teamwork.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Developing robust and reusable components using React.js.

            </li>
            <li className="about-activity">
              <ImPointRight /> Building scalable APIs and integrating them seamlessly with front-end applications.
            </li>
            <li className="about-activity">
              <ImPointRight /> Working with modern tools such as Astro and TailwindCSS to deliver visually appealing and efficient web designs.
            </li>
            <li className="about-activity">
              <ImPointRight /> Collaborating with cross-functional teams to deliver solutions that align with business goals.
            </li>
            
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Mohamed Arshad</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
