import axios from 'axios';
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

function CreativeForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    thoughts: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(formData);
   
    const data = {
        data:{
            fullName:formData.fullName,
            email:formData.email,
            phoneNumber:formData.phoneNumber,
            thoughts:formData.thoughts
        }
    }


    const apiUrl = "https://nexmart-api.onrender.com/api/contact-lists"  // Replace with your actual API endpoint

    try {
      // Using async/await to handle the API request
      const response = await axios.post(apiUrl, data);
      console.log('Success:', response.data);
      if(response.data.data){
        setFormData({
            fullName: '',
            email: '',
            phoneNumber: '',
            thoughts: '',
          })
          alert("Yeh! Success")
      }
      
    } catch (error) {
        alert("oooooops!")
      console.error('There was an error!', error);
     
    } 


    
  };

  return (
    <section>
      <Container fluid className="thoughts-section" id="thoughts">
      
    <Container className="mt-5 p-4 shadow-lg" style={{ backgroundColor: '#f7f7f7', borderRadius: '10px' }}>
      <h2 className="text-center mb-4" style={{ color: '#6c63ff', fontWeight: 'bold' }}>Share Your Thoughts</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="fullName">
              
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
                style={{ border: '2px solid #6c63ff', borderRadius: '10px', padding: '10px' }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="email">
             
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{ border: '2px solid #6c63ff', borderRadius: '10px', padding: '10px' }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="phoneNumber">
             
              <Form.Control
                type="text"
                placeholder="Enter your phone number with country code"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                style={{ border: '2px solid #6c63ff', borderRadius: '10px', padding: '10px' }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="thoughts">
             
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Share your thoughts"
                name="thoughts"
                value={formData.thoughts}
                onChange={handleChange}
                required
                style={{ border: '2px solid #6c63ff', borderRadius: '10px', padding: '10px' }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          type="submit"
          style={{
            backgroundColor: '#6c63ff',
            borderColor: '#6c63ff',
            borderRadius: '30px',
            padding: '10px 30px',
            fontWeight: 'bold',
          }}
          className="d-block mx-auto mt-4"
        >
          Submit
        </Button>
      </Form>
    </Container>
    </Container>
    </section>
  );
}

export default CreativeForm;
